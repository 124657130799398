import '../styles/styles.scss';
// import App from './app';

import { createPopper } from '@popperjs/core';
// import IMask from 'imask';
// import { MarkerClusterer } from "@googlemaps/markerclusterer";

// Bootstrap components
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import Alpine from 'alpinejs'
window.Alpine = Alpine
import collapse from '@alpinejs/collapse'
import persist from '@alpinejs/persist'
Alpine.plugin([collapse, persist])
Alpine.start()

const dayjs = require('dayjs')

const today = dayjs().format('YYYY-MM-DD');
const maxDate = dayjs(today).add(12, 'month').format('YYYY-MM-DD');

require("flatpickr/dist/themes/airbnb.css");
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"
flatpickr.localize(French);
globalThis.French = French;
flatpickr("#date-picker",{
    dateFormat: 'Y-m-d',
    maxDate: maxDate,
    minDate: today,
});


import './general';
import './swiper';
import './navigation';
import './form-range';
// import './imask';
// import './map';




